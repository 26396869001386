<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Page-privacy component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Privacy Policy </h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 23th Sep, 2020</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item"><a href="/terms">Terms</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Privacy</li>
                                    <li class="breadcrumb-item"><a href="/refund">Refund</a></li>
                                    <li class="breadcrumb-item"><a href="/disclosure">disclosure</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Privacy -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">
                            <h5 class="card-title">PRIVACY POLICY:</h5>

Your privacy is important to us, and this policy will inform you of the information that Karukaj DIGITAL may collect from you, how it is used, and/or disclosed. By using our Website, www.karukaj.com, you are acknowledging and accepting the practices and information described in this policy and on our Website.
<br>
&nbsp;<h4>Collecting Personal Information</h4>
When you visit our Website, we may collect personal and non-personal information such as: domain name, IP Address, type of Internet browser used, operating system, what brought you to our Website, which pages you visited, and the length of time you were on each page. We work with third parties, using cookies and other similar identifiers, to collect user data on our Website and elsewhere on the Internet for the purposes of tracking and advertising. Additionally, any information you willingly submit to us via our Website or otherwise will be collected. We may use this information to contact you in the future regarding specials, new products, services, or changes to this privacy policy.
<br>
&nbsp;<h4>Using Information</h4>
We may use the information we have collected primarily for our own internal purposes, including improving our products, services, Website, advertising, remarketing efforts, etc. However, we may also use your collected information to contact you to respond to inquiries or provide information about our services.
<br>
&nbsp;<h4>Protecting Information</h4>
Karukaj DIGITAL generally follows accepted industry standards when it comes to protecting the information you submit to us and that we gather, both during transmission and once we receive it. We use appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site.
<br>
&nbsp;<h4>Web Browser Cookies</h4>
We may use "cookies" to enhance your experience on our Website. A cookie is a piece of data placed on your hard drive for record-keeping purposes by your web browser that may sometimes track information about you. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent. If you do so, note that some parts of the Site may not function properly. Usage of a cookie is in no way linked to any personally identifiable information on our site.
<br>
&nbsp;<h4>Sharing Information</h4>
We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers. We may also disclose your customer information with third parties in order to perform services on our behalf, and we will obtain consent for such sharing where it is legally required.

We will disclose personal information and/or an IP Address when required by law or in good-faith belief that such an action is necessary to: cooperate with an investigation of purported unlawful activity, protect the rights or property of our Website and related properties, or identify persons who may be violating the law, the rights of third parties, or otherwise misusing our Website or its related properties.

Please keep in mind that if you voluntarily submit your information in a public forum on our related to our Website—such as a comment section, discussion board, etc.—it may be collected and used by others, and we have no control of how third parties may use your publically accessible information.
<br>
&nbsp;<h4>Third-Party Websites</h4>
Karukaj DIGITAL’s Website may contain links to third-party websites, including links to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website’s own terms and policies.
<br>
&nbsp;<h4>Your Consent</h4>
By using this site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.

If you would like to opt out of our tracking, you may do so here:<br><br>
<ul type="disc">
 	<li><a href="https://www.karukaj.com.bd/subscribe">https://www.karukaj.com.bd/subscribe/</a></li>
 	<li><a href="https://www.karukaj.com.bd/unsubscribe/">https://www.karukaj.com.bd/unsubscribe/</a></li>
</ul>

&nbsp;<h4>Privacy Policy Changes</h4>
Karukaj DIGITAL may make changes to this privacy policy at our discretion. When we do, we will revise the updated date at the bottom of this page. We encourage you to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. By using our Website, you acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
<br>
<br>


                            <a href="javascript:void(0)" class="btn btn-primary">Print</a>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->




<br><br><br>
      
        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h4 class="mb-4">Need help? We're always here for you</h4>
                        <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary font-weight-bold">Karukaj</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">


                        <router-link  class="btn btn-soft-primary font-weight-bold mt-4 text-center" to="/refund"> Read Refund Policy <arrow-right-icon class="fea icon-sm"></arrow-right-icon> </router-link> 


             
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->



    </section>
    <!--end section-->
    <!-- End Privacy -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
